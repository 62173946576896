<template>
  <div>
    <h3>Website Store Settings</h3>

    <v-btn text :to="{ name: 'manage-websites', params: { domainname: siteUrl }}" outlined
           class="btn btn-lg btn-secondary-main" style="background:transparent;">
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <br/>
    <br/>
    <v-app>
      <div class="row" v-if="loading">
        <div class="col-md-12 mt-2">
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
        </div>
      </div>
      <v-card class="mt-2" v-else-if="!loading">
        <v-tabs :vertical="!$vuetify.breakpoint.xsOnly" class="theme_settings">
          <v-tab>
            <v-icon left>mdi-cogs</v-icon>
            General
          </v-tab>
          <v-tab>
            <v-icon left>mdi-cogs</v-icon>
            Settings
          </v-tab>
          <v-tab>
            <v-icon left>mdi-map-marker</v-icon>
            Address
          </v-tab>
          <!--                <v-tab>-->
          <!--                    <v-icon left>mdi-truck-fast</v-icon>Delivery-->
          <!--                </v-tab>-->
          <v-tab>
            <v-icon left>mdi-script-text</v-icon>
            Policy
          </v-tab>
          <v-tab>
            <v-icon left>mdi-text</v-icon>
            Headings
          </v-tab>
          <v-tab>
            <v-icon left>far fa-file-image</v-icon>
            Image
          </v-tab>
          <v-tab>
            <v-icon left>fa fa-file-invoice</v-icon>
            Tax
          </v-tab>
          <v-tab>
            <v-icon left>fa fa-battery-three-quarters</v-icon>
            Order Status
          </v-tab>
          <v-tab>
            <v-icon left>fa fas fa-box-open</v-icon>
            Packaging Setting
          </v-tab>
          <!--          <v-tab>-->
          <!--            <v-icon left>fa far fa-money-bill-alt</v-icon>Currency Setting-->
          <!--          </v-tab>-->

          <v-tab-item>

            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Root Catalog</h3>
                <p>Select root category from your catalog for this website</p>
              </v-col>
              <v-col cols="12">
                <v-select :items="catalogroots" v-model="pageSetting.ecommerce_root_catalog_id" item-text="title"
                          item-value="id" outlined densed label="Select Catalog"></v-select>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="pageSetting.automatic_order_approval" label="Automatic Order Approval"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="pageSetting.review_enabled" label="Enable Review"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="pageSetting.show_filter" label="Enable Product Filter"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="pageSetting.enable_search" label="Enable Search"></v-switch>
              </v-col>
              <v-col cols="6">
                <v-select v-model="pageSetting.display_style" :items="['list','grid']" densed outlined
                          label="Product Display Style"></v-select>
              </v-col>
              <v-col cols="12">
                <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    hint="Upload Logo"
                    persistent-hint
                    prepend-inner-icon="mdi-camera"
                    prepend-icon=""
                    label="Cart Page Image"
                    v-model="pageSetting.cart_image"
                    @change="changeCartImage"
                    outlined
                ></v-file-input>
              </v-col>
              <v-col cols="12" v-if="pageSetting.cart_image_path && pageSetting.cart_image_path.real">
                <div class="logoPreview" id="logoPreview">
                  <img
                      v-if="pageSetting.cart_image_path.real"
                      style="max-height: 180px"
                      :src="pageSetting.cart_image_path.real"
                  />
                </div>
              </v-col>

              <v-col cols="12" class="text-right">
                <v-btn @click="updateSetting" color="btn btn-save">save</v-btn>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>
            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Ecommerce Settings</h3>
                <p>Manage Different Ecommmerce Settings</p>
              </v-col>
              <v-col>
                <ecommerce-settings :website="website" :header="header" :setting="pageSetting" :siteUrl="siteUrl"
                                    :isBusy="isLoading"></ecommerce-settings>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>
            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Store Address</h3>
                <p>Select store address, this may impact in delivery options for customer</p>
              </v-col>
              <v-col>
                <store-location :setting="pageSetting" :siteUrl="siteUrl" :isLoading="isLoading"></store-location>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab-item>

            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Policy Settings</h3>
              </v-col>
              <v-col cols="12">
                <h4>Terms and Conditions</h4>
                <ckeditor :config="editorConfig" v-model="pageSetting.terms_condition"></ckeditor>
              </v-col>
              <v-col cols="12">
                <h4>Privacy Policy</h4>
                <ckeditor :config="editorConfig" v-model="pageSetting.privacy_policy"></ckeditor>
              </v-col>
              <v-col cols="12">
                <h4>Refund Policy</h4>
                <ckeditor :config="editorConfig" v-model="pageSetting.refund_policy"></ckeditor>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="updateSetting" color="primary">save</v-btn>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>

            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Store Headings</h3>
                <p>Select store address, this may impact in delivery options for customer</p>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Cart Page Title" v-model="pageSetting.cart_page_title" required hint
                              persistent-hint outlined densed></v-text-field>

                <v-text-field label="Add To Cart Button Text" v-model="pageSetting.cart_button_text" required hint
                              persistent-hint outlined densed></v-text-field>

                <v-text-field label="Shop Page Title" v-model="pageSetting.shop_page_title" required outlined
                              densed></v-text-field>
                <v-text-field label="Thank you page Title" v-model="pageSetting.thank_you_page_title" required outlined
                              densed></v-text-field>
                <v-text-field label="Thank you page Text" v-model="pageSetting.thank_you_page_text" required outlined
                              densed></v-text-field>

                <v-text-field label="Wishlist Page Title" v-model="pageSetting.wishlist_page_title" required outlined
                              densed></v-text-field>
                <v-text-field label="Dashboard Page Title" v-model="pageSetting.dashboard_page_title" required outlined
                              densed></v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="updateSetting" color="primary">save</v-btn>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>

            <v-row class="pa-10">
              <v-col>
                <h3>Select Image Size</h3>
              </v-col>
              <v-col cols="12">
                <v-select label="Select Default Image Size" :items="image_types" item-text="title" item-value="value"
                          v-model="pageSetting.image_type" outlined densed></v-select>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="updateSetting" color="primary">save</v-btn>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab-item>

            <v-row class="pa-10">
              <v-col cols="12">
                <h3>Tax Setting</h3>
              </v-col>
              <v-col cols="12">
                <v-text-field type="number" outlined densed v-model="pageSetting.tax_percentage"
                              label="Tax Percentage"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field densed outlined v-model="pageSetting.tax_terms" label="Tax Terms"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="pageSetting.tax_type" outlined densed :items="['inclusive', 'exclusive']"
                          label="Tax Type"></v-select>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="updateSetting" color="primary" :loading="loading">save</v-btn>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>
            <v-row class="pa-10">
              <v-col cols="6">
                <h3>Order Status</h3>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn @click="createNewOrderStatus" color="primary">
                  <i class="fas fa-plus mr-2"></i> Order Status
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-simple-table :dense="dense" :fixed-header="fixedHeader" :height="height">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Title</th>
                      <th class="text-left">Notification</th>
                      <th class="text-left">Default</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in order_statuses" :key="item.name">
                      <td>{{ item.title }}</td>
                      <td>
                                                        <span :class="item.notify_user?'text-success':'text-danger'">
                                                            <i class="fas"
                                                               :class="item.notify_user?'fa-check':'fa-ban'"></i>
                                                        </span>
                      </td>
                      <td>
                                                        <span :class="item.is_default?'text-success':'text-danger'">
                                                            <i class="fas"
                                                               :class="item.is_default?'fa-check':'fa-ban'"></i>
                                                        </span>
                      </td>
                      <td>
                                                        <span :class="item.is_active?'text-success':'text-danger'">
                                                            <i class="fas"
                                                               :class="item.is_active?'fa-check':'fa-ban'"></i>
                                                        </span>
                      </td>
                      <td>
                                                        <span class="mr-2" @click.prevent="editOrderStatus(item)">
                                                            <i class="fa fa-edit"></i>
                                                        </span>
                        <a class="text-danger" @click.prevent="deleteOrderStatus(item.id)">
                          <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>
            <packaging-setting></packaging-setting>
          </v-tab-item>
        </v-tabs>
      </v-card>

      <v-dialog scrollable v-model="storeFrontDialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Store Front (HomePage) Content</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-if="selectedItem!=null">
                <v-tabs v-model="tab" background-color="transparent" grow>
                  <v-tab>Banner</v-tab>
                  <v-tab>Catalogs</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item key="Banner">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.title" dense label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input outlined v-model="selectedItem.banner" dense show-size counter
                                      label="banner"></v-file-input>
                      </v-col>
                      <v-col cols="12" v-if="selectedItem.type =='banner'">
                        <v-img :src="selectedItem.banner_image.real" :lazy-src="selectedItem.banner_image.real"
                               aspect-ratio="1" class="grey lighten-2" max-width="500" max-height="300">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.banner_alt_text" dense
                                      label="Banner Alt Text"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.banner_link" dense
                                      label="Banner Link"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea label="Description" outlined v-model="selectedItem.description" dense></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-select :items="columnNumber" label="column number" item-text="label" item-value="value"
                                  outlined v-model="selectedItem.column_number" dense></v-select>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="updateFrontStoreContent" text>Update</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="catalogs">
                    <v-row>
                      <v-col cols="12">
                        <cascader style="width:100%;" size="large" :options="catlogList" v-model="catlog_edited"
                                  :fieldNames="{
                                            label: 'title', value: 'id', children: 'children'
                                            }" placeholder="Please select Categroy" change-on-select/>
                      </v-col>
                      <v-col cols="12" v-if="selectedItem.type =='catalogs'">
                        Category :
                        <Strong v-text="selectedItem.catalog.title"></Strong>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.title" dense label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select :items="columnNumber" label="column number" item-text="label" item-value="value"
                                  outlined v-model="selectedItem.column_number" dense></v-select>
                      </v-col>
                      primary
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="updateFrontStoreContent" color="primary">Update</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                </v-tabs-items>
              </v-col>
              <v-col cols="12" v-else>
                <v-tabs v-model="tab" background-color="transparent" grow>
                  <v-tab>Banner</v-tab>
                  <v-tab>Catalogs</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item key="Banner">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.title" dense label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input outlined v-model="frontShop.banner" dense show-size counter
                                      label="banner"></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.banner_alt_text" dense
                                      label="Banner Alt Text"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.banner_link" dense label="Banner Link"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea label="Description" outlined v-model="frontShop.description" dense></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-select :items="columnNumber" label="column number" item-text="label" item-value="value"
                                  outlined v-model="frontShop.column_number" dense></v-select>
                      </v-col>

                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="createFrontStoreContent" text>Save</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="catalogs">
                    <v-row>
                      <v-col cols="12">
                        <cascader style="width:100%;" size="large" :options="catlogList" v-model="frontShop.catalog_id"
                                  :fieldNames="{
                                            label: 'title', value: 'id', children: 'children'
                                            }" placeholder="Please select Categroy" change-on-select/>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.title" dense label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select :items="columnNumber" label="column number" item-text="label" item-value="value"
                                  outlined v-model="frontShop.column_number" dense></v-select>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="createFrontStoreContent" text>Save</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="addshipmentDialog" non-persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Add Shipment</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h4>
                  Delivery
                  <strong v-if="deliverShipment.free_delivery">Free</strong>
                  <span>{{
                      deliverShipment.type == 'within_store_area' ? 'Inside Your' : deliverShipment.type == 'outside_store_area' ? ' Outside Your' : ''
                    }} Area</span>
                </h4>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-text-field outlined dense v-model="deliverShipment.title"
                              label="Delivery Option Name"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                    :items="[{label:'Inside Store Area',value:'within_store_area'},{label:'Outside Store Area',value:'outside_store_area'},]"
                    label="Type" item-text="label" item-value="value" v-model="deliverShipment.type" dense
                    outlined></v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-if="!deliverShipment.free_delivery">
                <v-text-field outlined dense v-model="deliverShipment.delivery_charge" prefix="Rs."
                              label="Delivery Price"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-if="deliverShipment.free_delivery">
                <v-text-field outlined dense v-model="deliverShipment.free_delivery_min_amount" prefix="Rs."
                              label="Above Minimum Amount"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-else>
                <v-text-field outlined dense v-model="deliverShipment.min_delivery_amount" prefix="Rs."
                              label="Above Minimum Amount"></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4">
                <v-switch v-model="deliverShipment.free_delivery" label="Free Delivery"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined dense v-model="deliverShipment.delivery_details"
                            label="Short information on this delivery"></v-textarea>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="closeShipmentAddDialog" color="primary" class="m-2">Cancel</v-btn>
                <v-btn @click="saveOrUpdateDelivery(deliverShipment)" color="primary">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="orderStatusDialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Add Order Status</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Order status title" outlined dense v-model="order_status.title"></v-text-field>
                <span class="text-danger" v-if="$v.order_status.title.$error">This is required field.</span>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="order_status.notify_user" label="Notify User"></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="order_status.is_default" label="Default"></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="order_status.is_active" label="Status"></v-switch>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="resetOrderStatus()" color="default" class="mr-2">Cancel</v-btn>
                <v-btn @click="saveAndUpdateOrderStatus(deliverShipment)" color="primary">Save</v-btn>
              </v-col>
              <v-col cols="12">
                <hr/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB
} from "@/store/breadcrumbs.module";
import Website from "@/services/Websites/WebsiteService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import OrganisationAddressService from "@/services/organisation/OrganisationAddressService";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
import {
  VueNestable,
  VueNestableHandle
} from "vue-nestable";
import WebsiteFrontStoreContent from "@/services/Websites/WebsiteFrontStoreContent";
import WebsiteShippingDetails from "@/services/Websites/WebsiteShippingDetails";
import WebsitePage from "@/services/Websites/WebsitePageService";
import Cascader from "ant-design-vue/lib/cascader";
import "ant-design-vue/dist/antd.css";
import {
  required
} from "vuelidate/lib/validators";
import ProductCatalogService from "@/services/store/ProductCatalogService";
import CKEditor from "ckeditor4-vue";
import EcommerceOrderStatusService from "@/services/store/EcommerceOrderStatusService";
import PackagingSetting from "./store-setting/PackagingSetting";
import CurrencySetting from "./store-setting/CurrencySetting";
import StoreLocation from "./store-setting/StoreLocation";
import Websitelayout from "./LayoutDialog";

const WebsiteService = new Website();
const WebsiteSettingService = new WebsiteSetting();
const organisationAddressService = new OrganisationAddressService();
const ProductRoot = new ProductCatalogRootService();
const WebsiteFrontStore = new WebsiteFrontStoreContent();
const WebsiteShipping = new WebsiteShippingDetails();
const WebsitePageService = new WebsitePage();
const ProductCatalog = new ProductCatalogService();
const orderStatusService = new EcommerceOrderStatusService();
import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";

const WebsiteHeaderService = new WebsiteHeader();
import EcommerceSettings from "./store-setting/EcommerceSettings";

export default {
  name: "website-settings",
  validations: {
    order_status: {
      title: {
        required
      }
    }
  },
  components: {
    VueNestable,
    VueNestableHandle,
    Cascader,
    PackagingSetting,
    CurrencySetting,
    Websitelayout,
    EcommerceSettings,
    ckeditor: CKEditor.component,
    StoreLocation
  },
  data() {
    return {
      editorConfig: {
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      loading: true,
      product_layoutDialog: false,
      orderStatusDialog: false,
      orderStatusEdit: false,
      order_status: {},
      dense: true,
      fixedHeader: true,
      height: 300,
      header: {},
      website: null,
      order_statuses: [],
      cropping_positions: [{
        value: "center",
        title: "Center (Default)"
      },
        {
          value: "top-left",
          title: "Top Left"
        },
        {
          value: "top",
          title: "Top"
        },
        {
          value: "top-right",
          title: "Top Right"
        },
        {
          value: "left",
          title: "Left"
        },
        {
          value: "right",
          title: "Right"
        },
        {
          value: "bottom-left",
          title: "Bottom Left"
        },
        {
          value: "bottom",
          title: "Bottom"
        },
        {
          value: "bottom-right",
          title: "Bottom Right"
        }
      ],
      orientations: [{
        value: "portrait",
        title: "Portrait (Default)"
      },
        {
          value: "landscape",
          title: "Landscape"
        }
      ],
      image_types: [{
        value: "xs",
        title: "Extra Small (80)"
      },
        {
          value: "s",
          title: "Small (160)"
        },
        {
          value: "m",
          title: "Medium (320)"
        },
        {
          value: "l",
          title: "Large (640)"
        },
        {
          value: "xl",
          title: "Extra Large (1280)"
        },
        {
          value: "org",
          title: "Original"
        }
      ],
      tab: null,
      storeFrontDialog: false,
      catalogroots: [],
      selectedRootId: null,
      addshipmentDialog: false,
      dialog: false,
      edit: false,
      showMaps: false,
      descriptionLimit: 60,
      isLoading: false,
      center: {
        lat: 28.3949,
        lng: 84.124
      },
      model: null,
      cities: [],
      areas: [],
      states: [],
      countries: [],
      pages: ["collection"],
      catlog_edited: [],
      ecomStoreHeaderPage: null,
      ecomStoreFooterPage: null,
      selectedItem: null,
      deliverShipment: {
        id: null,
        website_id: null,
        title: null,
        type: null,
        free_delivery: 0,
        is_delivered_online: 0,
        delivery_charge: 0,
        estimated_days: null,
        delivery_details: null,
        min_delivery_amount: null,
        free_delivery_min_amount: null
      },
      deliverInside: {
        id: null,
        website_id: null,
        title: null,
        type: "within_store_area",
        free_delivery: 0,
        is_delivered_online: 0,
        delivery_charge: 0,
        estimated_days: null,
        delivery_details: null,
        min_delivery_amount: null,
        free_delivery_min_amount: null
      },
      deliverOutside: {
        id: null,
        website_id: null,
        title: null,
        type: "outside_store_area",
        free_delivery: 0,
        is_delivered_online: 0,
        delivery_charge: 0,
        estimated_days: null,
        delivery_details: null,
        min_delivery_amount: null,
        free_delivery_min_amount: null
      },
      shippmentDetails: [],
      pageSetting: {
        id: null,
        site_logo: null,
        site_favicon: null,
        site_title: null,
        site_tagline: null,
        site_phone: null,
        site_email: null,
        site_fax: null,
        business_address: null,
        seo_title: null,
        seo_description: null,
        social_share_image: null,
        social_share_description: null,
        enable_powered_by: 1,
        mailchimp_api_key: null,
        google_map_api_key: null,
        google_analytics_api_key: null,
        google_map_embed_url: null,
        header_design_id: null,
        footer_design_id: null,
        cart_page_design_id: null,
        cart_page_title: null,
        cart_button_text: null,
        cart_page_header: null,
        cart_page_subheader: null,
        cart_page_banner: null,
        shop_main_design_id: null,
        shop_page_title: null,
        shop_page_header: null,
        shop_page_subheader: null,
        shop_page_banner: null,
        product_per_page: null,
        thank_you_page_design_id: null,
        thank_you_page_title: null,
        thank_you_page_text: null,
        thank_you_page_header: null,
        thank_you_page_subheader: null,
        thank_you_page_banner: null,
        checkout_page_design_id: null,
        blog_main_design_id: null,
        blog_single_design_id: null,
        blog_page_title: null,
        blog_page_header: null,
        blog_page_subheader: null,
        blog_page_banner: null,
        blog_per_page: null,
        is_system_ecommerce_page: null,
        is_system_ecommerce_header_enable: null,
        is_system_ecommerce_footer_enable: null,
        terms_condition: null,
        refund_policy: null,
        privacy_policy: null,
        is_active: 0,
        deleted_at: null,
        updated_at: null
      },
      rules: [
        value =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!"
      ],
      frontShop: {
        title: "",
        banner: "",
        banner_alt_text: "",
        banner_link: "",
        description: "",
        column_number: "",
        product_number: "",
        position: "",
        is_active: ""
      },
      columnNumber: [{
        label: "1 Column",
        value: 12
      },
        {
          label: "2 Column",
          value: 6
        },
        {
          label: "3 Column",
          value: 4
        },
        {
          label: "4 Column",
          value: 3
        }
      ],
      productNumber: [{
        label: "1 Column",
        value: 1
      },
        {
          label: "2 Column",
          value: 2
        },
        {
          label: "3 Column",
          value: 3
        },
        {
          label: "4 Column",
          value: 4
        }
      ],

      address: {
        organisation_id: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        area: "",
        state: "",
        province: "",
        geolocation_id: "",
        geo_lat: "",
        geo_long: "",
        is_primary: ""
      },
      catlogList: [],
      frontStoreContents: [{
        id: 1,
        banner: null,
        banner_alt_text: null,
        banner_link: null,
        catalog_id: null,
        column_number: 1,
        created: null,
        description: null,
        is_active: null,
        product_number: null,
        sort_position: null,
        title: null,
        website_id: 1
      }],
      nestableFrontStoreContents: [],
      cartImageUrl: null
    };
  },
  methods: {
    getCatalogsRoots() {
      ProductRoot.paginate().then(res => {
        this.catalogroots = res.data;
        this.loading = false;
      });
    },
    getWebsite() {
      WebsiteService.get(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
    changeCartImage(e) {
      this.pageSetting.cart_image = e;
      this.cartImageUrl = URL.createObjectURL(e);
    },
    openDialog() {
      this.storeFrontDialog = true;
    },
    closeDialog() {
      this.storeFrontDialog = false;
    },
    openShipmentAddDialog() {
      this.addshipmentDialog = true;
    },
    closeShipmentAddDialog() {
      this.addshipmentDialog = false;
    },
    generateFormData() {
      let fd = new FormData();
      let data = {
        ecommerce_root_catalog_id: this.pageSetting.ecommerce_root_catalog_id,
        automatic_order_approval: this.pageSetting.automatic_order_approval,
        review_enabled: this.pageSetting.review_enabled,
        show_filter: this.pageSetting.show_filter,
        enable_search: this.pageSetting.enable_search,
        display_style: this.pageSetting.display_style,
        cart_image: this.pageSetting.cart_image,
        terms_condition: this.pageSetting.terms_condition,
        privacy_policy: this.pageSetting.privacy_policy,
        refund_policy: this.pageSetting.refund_policy,
        cart_page_title: this.pageSetting.cart_page_title,
        cart_button_text: this.pageSetting.cart_button_text,
        shop_page_title: this.pageSetting.shop_page_title,
        thank_you_page_title: this.pageSetting.thank_you_page_title,
        thank_you_page_text: this.pageSetting.thank_you_page_text,
        wishlist_page_title: this.pageSetting.wishlist_page_title,
        dashboard_page_title: this.pageSetting.dashboard_page_title,
        image_type: this.pageSetting.image_type,
        tax_percentage: this.pageSetting.tax_percentage,
        tax_terms: this.pageSetting.tax_terms,
        tax_type: this.pageSetting.tax_type,
      }
      if (data.cart_image == undefined || data.cart_image == null || data.cart_image == '' ){
        delete  data.cart_image;
      }
      for (let key in data) {
        if (key == "cart_image") {
          fd.append('cart_image', this.pageSetting.cart_image)
        }
        else if (key == "enable_search" && this.pageSetting["enable_search"]) {
          fd.append("enable_search", this.pageSetting['enable_search'] ? 1 : 0);
        }
        else if (key == "review_enabled" && this.pageSetting["review_enabled"]) {
          fd.append("review_enabled", this.pageSetting['review_enabled'] ? 1 : 0);
        }
        else if (key == "show_filter" && this.pageSetting["show_filter"]) {
          fd.append("show_filter", this.pageSetting['show_filter'] ? 1 : 0);
        }
        else if (key == "automatic_order_approval" && this.pageSetting["automatic_order_approval"]) {
          fd.append("automatic_order_approval", this.pageSetting['automatic_order_approval'] ? 1 : 0);
        }
        else {
          fd.append(key, this.pageSetting[key])
        }
      }
      return fd;
    },
    updateSetting() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let fd = this.generateFormData();
            WebsiteSettingService.update(
                this.siteUrl,
                this.pageSetting.id,
                fd
            ).then(res => {
              this.$snotify.success("Settings  Updated");
              this.getSettings();
            });
          }
        }
      });
    },
    getHeader() {
      WebsiteHeaderService.paginate(this.siteUrl).then(res => {
        if (res.data) {
          this.header = res.data;
          this.header.sub_header_background_path
              ? (this.header_sub_background = this.header.sub_header_background_path.real)
              : null;
          this.loading = false;
        }
      });
    },
    getSettings() {
      WebsiteSettingService.paginate(this.siteUrl).then(res => {
        this.pageSetting = res.data;
        this.loading = false;
        if (this.pageSetting.ecommerce_root_catalog_id) {
          this.getCatalogs(this.pageSetting.ecommerce_root_catalog_id);
        }
      });
    },
    editSelected(item) {
      this.tab = item.type == "catalogs" ? 1 : 0;
      this.selectedItem = item;
      this.selectedItem.banner = null;
      this.openDialog();
    },
    sortNow(data) {
      WebsiteFrontStore.sort(this.siteUrl, data).then(res => {
        this.$snotify.success("Settings  Updated");
        this.getFrontStoreContent();
      });
    },

    getFrontStoreContent() {
      WebsiteFrontStore.paginate(this.siteUrl).then(res => {
        this.frontStoreContents = res.data;
      });
    },
    deleteFront(id) {
      this.$confirm({
        message: `Confirm delete  item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteFrontStore.delete(this.siteUrl, id).then(res => {
              this.$snotify.success("Deleted");
              this.getFrontStoreContent();
            });
          }
        }
      });
    },
    createFrontStoreContent() {
      this.loading = true;
      let type = this.tab == 0 ? "banner" : "catalogs";
      this.frontShop.type = type;
      if (this.tab === 1) {
        this.frontShop.catalog_id = this.frontShop.catalog_id.slice(-1).pop();
      }

      let fd = new FormData();
      for (var key in this.frontShop) {
        if (
            key === "banner" &&
            (this.frontShop["banner"] == null ||
                this.frontShop["banner"] == undefined)
        ) {
        } else if (
            key === "catalog_id" &&
            (this.frontShop["catalog_id"] == null ||
                this.frontShop["catalog_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.frontShop[key]);
        }
      }
      WebsiteFrontStore.create(this.siteUrl, fd).then(res => {
        this.loading = false;

        this.getFrontStoreContent();
        this.resetFrontContent();
        this.closeDialog();
        this.$snotify.success("Settings  Created");
      });
    },
    resetFrontContent() {
      this.ffrontShop = null;
      this.selectedItem = null;
    },
    getCatalogs(id) {
      ProductCatalog.getCatlogByRootId(id).then(res => {
        this.catlogList = res.data;
      });
    },

    changeInvoiceType(value) {
      // console.log(value);
    },
    updateFrontStoreContent() {
      this.loading = true;

      let type = this.tab == 0 ? "banner" : "catalogs";
      this.selectedItem.type = type;
      if (this.type == "catalogs") {
        this.selectedItem.catalog_id = this.selectedItem.catalog_id
            .slice(-1)
            .pop();
      }

      let fd = new FormData();
      for (var key in this.selectedItem) {
        if (
            key === "banner" &&
            (this.selectedItem["banner"] == null ||
                this.selectedItem["banner"] == undefined)
        ) {
        } else if (
            key === "catalog_id" &&
            (this.selectedItem["catalog_id"] == null ||
                this.selectedItem["catalog_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.selectedItem[key]);
        }
      }
      WebsiteFrontStore.update(this.siteUrl, this.selectedItem.id, fd).then(
          res => {
            this.loading = false;

            this.getFrontStoreContent();
            this.resetFrontContent();
            this.closeDialog();
            this.$snotify.success("Settings  Updated");
          }
      );
    },
    updateHomePageSetting() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            if (this.pageSetting.is_system_ecommerce_page) {
              this.pageSetting.home_page_id = null;
            }
            this.updateSetting();
          }
        }
      });
    },
    getHeaderPage(type = "ecommerce_header", flag = null) {
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.ecomStoreHeaderPage = res.data.pages[0];
      });
    },
    getFooterPage(type = "ecommerce_footer", flag = null) {
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.ecomStoreFooterPage = res.data.pages[0];
      });
    },
    resetDeliver() {
      this.deliverShipment = {
        id: null,
        website_id: null,
        title: null,
        type: null,
        free_delivery: 0,
        is_delivered_online: 0,
        delivery_charge: 0,
        estimated_days: null,
        delivery_details: null,
        min_delivery_amount: null,
        free_delivery_min_amount: null
      };
    },

    saveOrUpdateDelivery(item) {
      if (item.id == null) {
        WebsiteShipping.create(this.siteUrl, item)
            .then(res => {
              this.getShippingDetails();
              this.closeShipmentAddDialog();
              this.resetDeliver();
              this.$snotify.success("Shipping Detail  Created");
            })
            .catch(err => {
            });
      } else {
        WebsiteShipping.update(this.siteUrl, item.id, item)
            .then(res => {
              this.getShippingDetails();
              this.resetDeliver();
              this.$snotify.success("Shipping Detail  Updated");
            })
            .catch(err => {
            });
      }
    },

    deleteShipment(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteShipping.delete(this.siteUrl, id).then(res => {
              this.$snotify.success("Deleted");
              this.getShippingDetails();
            });
          }
        }
      });
    },

    getShippingDetails() {
      WebsiteShipping.paginate(this.siteUrl)
          .then(res => {
            if (res.data.length > 0) {
              this.shippmentDetails = res.data;
            }
          })
          .catch(err => {
          });
    },
    noOfInvoiceDigit() {
      let no = this.pageSetting.invoice_no_of_digit;
      if (no > 6) {
        this.pageSetting.invoice_no_of_digit = 6;
      }
    },
    getOrderStatus() {
      orderStatusService
          .paginate(this.siteUrl)
          .then(response => {
            this.order_statuses = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    createNewOrderStatus() {
      this.orderStatusDialog = true;
    },
    saveAndUpdateOrderStatus() {
      this.loading = true;
      this.$v.order_status.$touch();
      if (this.$v.order_status.$error) {
        setTimeout(() => {
          this.$v.order_status.$reset();
        }, 3000);
      } else {
        if (this.orderStatusEdit) {
          this.updateOrderStatus();
        } else {
          this.saveOrderStaus();
        }
      }
    },
    saveOrderStaus() {
      orderStatusService
          .create(this.siteUrl, this.order_status)
          .then(response => {
            this.resetOrderStatus();
          })
          .catch(error => {
            // console.log(error);
          })
          .finally((this.loading = false));
    },
    updateOrderStatus() {
      orderStatusService
          .update(this.siteUrl, this.order_status.id, this.order_status)
          .then(response => {
            this.resetOrderStatus();
          })
          .catch(error => {
            // console.log(error);
          })
          .finally((this.loading = false));
    },
    deleteOrderStatus(id) {
      this.$confirm({
        message: `Confirm delete  item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            orderStatusService
                .delete(this.siteUrl, id)
                .then(res => {
                  this.resetOrderStatus();
                })
                .catch(error => {
                  // console.loading(error);
                });
          }
        }
      });
    },
    editOrderStatus(item) {
      this.order_status = item;
      this.orderStatusDialog = true;
      this.orderStatusEdit = true;
    },
    resetOrderStatus() {
      this.orderStatusDialog = false;
      this.orderStatusEdit = false;
      this.order_status = {};
      this.$v.order_status.$reset();
      this.getOrderStatus();
    },
    updateWebsiteSetting(data) {
      this.loading = true;
      this.$confirm({
        message: `Confirm update settings?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteSettingService.update(
                this.siteUrl,
                this.pageSetting.id,
                data
            ).then(res => {
              this.$snotify.success("Settings updated");
              this.getSettings();
            });
          }
        }
      });
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth;
    },
    siteUrl() {
      return this.$route.params.domainname;
    }
  },

  watch: {},
  mounted() {
    this.getCatalogsRoots();
    this.getSettings();
    this.getOrderStatus();
    this.getWebsite();
    this.getHeader();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Manage Pages",
      route: "/websites"
    }]);
  }
};
</script>
