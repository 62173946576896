<template>
  <v-app>
    <v-row v-if="currentUser.country_code =='AUS' || currentUser.country_code =='NPL'">
     <v-col cols="12">
       <v-autocomplete
               v-model="address.country_id"
               :items="countries"
               :loading="loading"
               hide-no-data
               hide-selected
               item-text="title"
               item-value="id"
               label="Find Your Country"
               placeholder="Start typing to Search your place"
               prepend-inner-icon="mdi-database-search"

               outlined
               densed
       ></v-autocomplete>
     </v-col>
      <v-col cols="12" >
        <v-autocomplete
                v-if="address.country_id == 155"
          v-model="model"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          item-text="Address"
          item-value="area"
          @change="placechange"
          label="Find Your Place"
          placeholder="Start typing to Search your place"
          prepend-inner-icon="mdi-database-search"
          return-object
          outlined
          densed
        ></v-autocomplete>

        <v-text-field
          label="Address Line1"
          v-model="address.address_line_1"
          required
          hint
          persistent-hint
          outlined
          densed
        ></v-text-field>

        <v-text-field
          label="Address Line 2"
          v-model="address.address_line_2"
          required
          outlined
          densed
        ></v-text-field>

        <v-combobox
          v-if="model && model.country =='Nepal'"
          v-model="address.area"
          :items="areas"
          hide-selected
          label="Area"
          persistent-hint
          outlined
          densed
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "
                  <!-- <strong>{{ search }}</strong>" -->
                  Press
                  <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <v-text-field
          v-else
          v-model="address.area"
          :items="areas"
          hide-selected
          outlined
          densed
          label="Area"
        ></v-text-field>
        <v-combobox
          v-model="address.city"
          :items="cities"
          hide-selected
          label="City"
          persistent-hint
          outlined
          densed
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "
                  <!-- <strong>{{ search }}</strong>" -->
                  . Press
                  <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <p
          class="text-danger font-weight-bold"
          v-if="$v.address.city.$error"
        >{{validationMessage.city}}</p>

        <v-combobox
          v-model="address.state"
          :items="states"
          hide-selected
          label="State"
          autocomplete="off"
          persistent-hint
          outlined
          densed
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "
                  <!-- <strong>{{ search }}</strong>" -->
                  . Press
                  <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <p
          class="text-danger font-weight-bold"
          v-if="$v.address.state.$error"
        >{{validationMessage.state}}</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="6" md="6" lg="6">
        <v-select
          :items="countries"
          v-model="address.country"
          outlined
          dense
          item-value="title"
          item-text="title"
          label="Country"
          @change="getStates()"
        ></v-select>
      </v-col>
      <v-col sm="6" md="6" lg="6">
        <v-select
          :items="states"
          v-model="address.state"
          outlined
          dense
          item-value="title"
          item-text="title"
          label="State"
          @change="getCities()"
        ></v-select>
      </v-col>
      <v-col sm="6" md="6" lg="6">
        <v-select
          :items="cities"
          v-model="address.city"
          outlined
          dense
          item-value="title"
          item-text="title"
          label="City"
        ></v-select>
      </v-col>
      <v-col sm="6" md="6" lg="6">
        <v-text-field
          v-model="address.address_line_1"
          outlined
          dense
          label="Street address, P.O. box"
        ></v-text-field>
      </v-col>
      <v-col sm="6" md="6" lg="6">
        <v-text-field v-model="address.post_code" outlined dense label="Postal code"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn @click="updateLocation" color="primary">save</v-btn>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import OrganisationAddressService from "@/services/organisation/OrganisationAddressService";
import WebsiteStoreLocation from "@/services/Websites/WebsiteStoreLocation";
import CountryService from "@/services/address/CountryService";
import StateService from "@/services/address/StateService";
import CityService from "@/services/address/CityService";
import { required } from "vuelidate/lib/validators";

const storeLocation = new WebsiteStoreLocation();
const organisationAddressService = new OrganisationAddressService();
const country = new CountryService();
const state = new StateService();
const city = new CityService();

export default {
  name: "store-location",
  props: ["setting", "siteUrl"],

  validations: {
    address: {
      address_line_1: { required },
      city: { required },
      area: { required },
      state: { required }
    },
    order_status: {
      title: { required }
    }
  },
  data() {
    return {
      loading: false,
      search: null,
      country: null,
      entries: [],
      model: null,
      cities: [],
      areas: [],
      states: [],
      countries: [],
      address: {
        organisation_id: "",
        address_line_1: "",
        address_line_2: "",
        // post_code: "",
        city: "",
        area: "",
        state: "",
        province: "",
        // country_id: "",
        geolocation_id: "",
        geo_lat: "",
        geo_long: "",
        is_primary: ""
      }
    };
  },
  mounted() {
    this.getLocation();
    this.getCountries();
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (val.length < 2) return;

      // Items have already been requested
      if (this.loading) return;

      this.loading = true;

      // Lazily load input items
      this.entries = [];
      organisationAddressService
        .getAddress(val)
        .then(res => {
          const { count, result } = res.data;
          this.count = count;
          this.entries = result;
        })
        .catch(err => {})
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    getCountries(){
      country.all().then(res=>{
        this.countries = res.data;
      })
    },
    getLocation() {
      storeLocation.show(this.siteUrl).then(res => {
        if (res.data.length > 0) {
          this.address = res.data[0];
          if (
            this.currentUser.country_code != "AUS" &&
            this.currentUser.country_code != "NPL" &&
            this.address.id
          ) {
            this.getStates();
          }
        }
      });
    },
    getCountries() {
      country.all().then(res => {
        this.countries = res.data;
      });
    },
    getStates() {
      state.all(this.address.country).then(res => {
        this.states = res.data;
         if (
            this.currentUser.country_code != "AUS" &&
            this.currentUser.country_code != "NPL" &&
            this.address.id
          ) {
            this.getCities();
          }
      });
    },
    getCities() {
      city.all(this.address.country, this.address.state).then(res => {
        this.cities = res.data;
      });
    },
    placechange() {
      this.address.city = this.model.city;
      this.address.area = this.model.area;
      this.address.state = this.model.state;
      this.addreplacechangess.province = this.model.province;
      this.address.geolocation_id = this.model.id;
      this.address.geo_lat = this.model.geo_lat;
      this.address.geo_long = this.model.geo_long;
      this.address.geolocation_id = this.model.id;
    },
    updateLocation() {
      if (this.address.id) {
        storeLocation
          .update(this.siteUrl, this.address.id, this.address)
          .then(res => {
            this.$snotify.success("Settings  Updated");
          });
      } else {
        storeLocation.create(this.siteUrl, this.address).then(res => {
          this.$snotify.success("Settings  Updated");
        });
      }
    }
  },
  computed: {
    items() {
      let Address = null;
      return this.entries.map((entry, index) => {
        let area = entry.area ? entry.area + ", " : "";
        let city = entry.city ? entry.city + ", " : "";
        let state = entry.state ? entry.state : "";
        let country = entry.country ? entry.country : "";
        Address = area + city + state + country;
        // console.log(Address);
        return Object.assign({}, entry, { Address });
      });
    },
    currentUser() {
      // console.log(this.$store.getters.currentUser);
      return this.$store.getters.currentUser;
    }
  }
};
</script>
<style >
  /*.v-menu__content ,theme--light, menuable__content__active{*/
  /*  top: 208px !important;*/
  /*  left: 275px !important;*/
  /*}*/
</style>