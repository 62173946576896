<template>
    <v-row class="pa-5">

        <v-row class="ps-10 pt-n10" v-if="setting.sell_product_online">
            <v-tabs class="theme_settings">
                <v-tab>
                    Headers
                </v-tab>
                <v-tab>
                    Products/ Details Page
                </v-tab>
                <v-tab>
                    Welcome User Styles
                </v-tab>
                <v-tab-item>
                    <v-row class="pa-10">
                        <v-col cols="12">
                            <h5>Ecommerce & Login</h5>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Login Menu" v-model="header.display_login_link"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Registration Menu" v-model="header.display_register_link"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Wishlist Menu" v-model="header.display_wishlist_icon"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Cart Icon" v-model="header.display_cart_icon"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Hide Logo on Auth Pages" v-model="setting.auth_hide_logo"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Front Categories" v-model="header.show_categories"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-text-field dense label="Front Categories text" outlined
                                          v-model="header.header_categories_text"></v-text-field>
                        </v-col>
                        <v-col class="text-right" cols="12">
                            <v-btn :loading="isBusy" @click="updateHeader" bottom color="primary">save</v-btn>
                        </v-col>

                    </v-row>

                </v-tab-item>
                <v-tab-item>
                    <v-row>
                        <v-col cols="12">
                            <h5>Product Details</h5>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Product Attributes "
                                      v-model="setting.show_products_attributes"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Product Details" v-model="setting.show_products_details"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Product Reviews" v-model="setting.show_products_reviews"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Product Warranty" v-model="setting.show_products_warranty"></v-switch>
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-switch label="Show Product Sidebar Info" v-model="setting.show_other_info"></v-switch>
                        </v-col>
                        <v-col cols="12" md="5" sm="12">
                            <v-text-field dense label="Product Details Banner text" outlined
                                          v-model="setting.product_detail_banner_text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" sm="12">
                            <v-file-input dense label="Banner Image" outlined
                                          v-model="setting.product_detail_banner_image"></v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="setting.product_detail_banner_path">
                            <v-img :src="setting.product_detail_banner_path.thumb">

                            </v-img>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                            <v-row :key="index" v-for="(product,index) in product_details">
                                <v-col cols="12" md="1" sm="12">
                                    <i :class="product.icon"></i>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <vue-awesome-icon-picker :title="'Yelko Icon Picker'" @selected="(...args)=>onIconSelected([index,...args])"
                                                             v-model="product.icon"/>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <v-text-field dense label="Product Details Title" outlined
                                                  v-model="product.title"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" sm="12">
                                    <v-text-field dense label="Product Details Short Details" outlined
                                                  v-model="product.details"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">

                                    <v-btn @click="removeProductDetails(index)" class="ms-2"
                                           color="red"
                                           fab
                                           outlined
                                           small
                                           v-if="index>0">
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                    <v-btn @click="addProductDetails()"
                                           class="ms-2"
                                           color="green"
                                           fab
                                           outlined
                                           small
                                           v-if="product_details.length-1 == index">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-col>
                        <hr>

                        <v-col cols="12" md="3" sm="12">
                            <v-select :items="[2,3,4]" dense label="Product Per Row" outlined
                                      v-model="setting.product_per_row">

                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <v-select :items="[10,15,20]" dense label="Product Per Page" outlined
                                      v-model="setting.product_per_page">

                            </v-select>
                        </v-col>
                        <v-col class="text-right" cols="12" v-if="setting.sell_product_online">
                            <v-btn :loading="isBusy" @click="updateSetting" bottom color="primary">save</v-btn>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <v-col cols="12" md="12" sm="12">
                        <v-radio-group row v-model="header.member_login_bar_style">
                            <v-col cols="12">
                                <h5>Welcome User Style</h5>
                            </v-col>

                            <v-radio :value="'image'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" outlined>
                                        <img src="/assets/media/icons/header-intro/icon.jpg"/>

                                        <v-card-title>Icon</v-card-title>
                                    </v-card>
                                </template>
                            </v-radio>
                            <v-radio :value="'full_name'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" outlined>
                                        <img src="/assets/media/icons/header-intro/name.jpg"/>

                                        <v-card-title>Name</v-card-title>
                                    </v-card>
                                </template>
                            </v-radio>
                            <v-radio :value="'both'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" outlined>
                                        <img src="/assets/media/icons/header-intro/icon_name.jpg"/>

                                        <v-card-title>Mix</v-card-title>
                                    </v-card>
                                </template>
                            </v-radio>
                            <v-radio :value="' '">
                                <template v-slot:label>
                                    <v-card class="mx-auto" outlined>
                                        <img src="/assets/media/icons/header-intro/none.jpg"/>

                                        <v-card-title>None</v-card-title>
                                    </v-card>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <v-col cols="12" md="12" sm="12">
                            <v-text-field
                                    densed
                                    label="Welcome Text. Eg: Welcome, ..."
                                    outlined
                                    v-model="header.member_login_welcome_text"
                            ></v-text-field>
                        </v-col>
                        <hr/>

                        <v-radio-group row v-model="header.cart_icon">
                            <v-col cols="12">
                                <h5>Cart Icon</h5>
                            </v-col>

                            <v-radio :value="'default'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" max-height="80" max-width="80" outlined>
                                        <img
                                                src="https://img.icons8.com/ios/50/000000/shopping-bag.png"
                                                style="max-height: 70px"
                                        />
                                    </v-card>
                                </template>
                            </v-radio>

                            <v-radio :value="'basket'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" max-height="80" max-width="80" outlined>
                                        <img
                                                src="https://img.icons8.com/wired/64/000000/shopping-basket-2.png"
                                                style="max-height: 70px"
                                        />
                                    </v-card>
                                </template>
                            </v-radio>
                            <v-radio :value="'cart'">
                                <template v-slot:label>
                                    <v-card class="mx-auto" max-height="80" max-width="80" outlined>
                                        <img
                                                src="https://img.icons8.com/ios/50/000000/shopping-cart.png"
                                                style="max-height: 70px"
                                        />
                                    </v-card>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col class="text-right" cols="12">
                        <v-btn :loading="isBusy" @click="updateHeader" bottom color="primary">save</v-btn>
                    </v-col>
                </v-tab-item>
            </v-tabs>


        </v-row>


    </v-row>
</template>
<script>
    import VueAwesomeIconPicker from '@rightbraintechbd/vue-awesome-icon-picker';
    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
    import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";

    const WebsiteSettingService = new WebsiteSetting();
    const WebsiteHeaderService = new WebsiteHeader();
    export default {
        name: "ecommerce--front-setting",
        data() {
            return {
                isBusy: false,
                product_details: [
                    {
                        icon: null,
                        title: null,
                        details: null,
                    }
                ],
            }
        },
        props: ["website", "setting", "header"],
        components: {
            'vue-awesome-icon-picker': VueAwesomeIconPicker
        },
        mounted() {
            if (this.setting.product_detail_other_info) {
                this.product_details = JSON.parse(this.setting.product_detail_other_info);
            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            },

        },
        methods: {
            onIconSelected(icon) {
                this.product_details[icon[0]]['icon'] = `${icon[1]['type']} fa-${icon[1]['name']}`;
            },
            updateHeader() {
                this.isBusy = true;
                WebsiteHeaderService.update(this.siteUrl, this.header.id, this.header).then(
                    res => {
                        this.$snotify.success("PageHeader Settings Updated");
                    }
                ).finally(() => {
                    this.isBusy = false;
                });
            },

            saveHeader() {
                this.setting.product_detail_other_info = JSON.stringify(this.product_details);
                this.$emit('save_header')
                // this.$emit('update_setting')
            }, enableDisableShopPage() {
                this.$emit('toggle_shop_enable')
            },
            updateSetting() {
                this.setting.product_detail_other_info = JSON.stringify(this.product_details);
                this.isBusy = true;
                let data = {};
                if (this.setting.product_detail_banner_image && this.setting.product_detail_banner_image !== null)
                     data = this.formData();
                else
                     data = this.setting;
                WebsiteSettingService.update(
                    this.siteUrl,
                    this.setting.id,
                    data
                ).then(res => {
                    this.$snotify.success("Settings  Updated");
                }).finally(() => {
                    this.isBusy = false;

                });
            },
            formData() {
                let fd = new FormData();
                if (this.setting.product_detail_banner_image && this.setting.product_detail_banner_image !== null) {
                    fd.append("product_detail_banner_image", this.setting.product_detail_banner_image, this.setting.product_detail_banner_image.name);
                }
                fd.append("show_products_attributes", this.setting.show_products_attributes);
                fd.append("show_products_details", this.setting.show_products_details);
                fd.append("show_products_reviews", this.setting.show_products_reviews);
                fd.append("show_products_warranty", this.setting.show_products_warranty);
                fd.append("show_other_info", this.setting.show_other_info);
                fd.append("product_detail_banner_text", this.setting.product_detail_banner_text);
                fd.append("product_detail_other_info", this.setting.product_detail_other_info);
                return fd;
            },
            addProductDetails() {
                this.product_details.push({
                    icon: null,
                    title: null,
                    details: null,
                });
            },
            removeProductDetails(index) {
                this.product_details.splice(index, 1);
            }
        }
    };
</script>
