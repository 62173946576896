import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteStoreLocation {

    paginate(slug, filter, index = null) {
        let url = API_URL + 'user/website/' + slug + '/store';
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/store'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/store/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/store/' + id
        return apiService.delete(url);
    }
    show(slug) {
        let url = API_URL + 'user/website/' + slug + '/store'
        return apiService.get(url);
    }


}
