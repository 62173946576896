import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class EcommerceOrderStatus {

    #api;

    constructor(){
        this.#api = `user/website`
    }

    paginate( domainName, index = null) {
        let url = `${this.#api}/${domainName}/order-status`
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    create( domainName, data) {
        let url = `${this.#api}/${domainName}/order-status`
        return apiService.post(url, data);
    }

    update( domainName, id, data) {
        let url = `${this.#api}/${domainName}/order-status/${id}`;
        return apiService.put(url, data);
    }

    delete( domainName, id) {
        let url = `${this.#api}/${domainName}/order-status/${id}`;
        return apiService.delete(url);
    }
    show( domainName, id) {
        let url = `${this.#api}/${domainName}/order-status/${id}`;
        return apiService.get(url);
    }
    generate( domainName) {
        let url = `${this.#api}/${domainName}/order-status/generate/default`;
        return apiService.get(url);
    }

     byWebsite( id) {
        let url = `${this.#api}/${id}/all/order-status`;
        return apiService.get(url);
    }
}
