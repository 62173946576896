<template>
    <v-app>
        <v-card>
            <v-card-text>
                <v-radio-group v-model="selectedLayout" column v-if="layouts.length>0">
                    <template v-slot:label>
                        <div>Select  <strong>Header Layout</strong></div>
                    </template>
                    <v-radio width="100%" v-for="(layout,i) in layouts" :key="i" :value="layout">
                        <template v-slot:label>
                            <v-card outlined width="100%">
                                <v-card-title>
                                    {{ layout.title }}
                                    <span v-if="layout.id ===pageSetting.header_design_id" class="btn btn-success ml-4">Selected</span>
                                    <span @click="previewImage(layout)" class="pull-right  ml-4 btn btn-warning"><i class="fa fa-eye"></i> Preview</span>
                                </v-card-title>
                                <progressive-img :src="layout.thumbnail_path['real']"></progressive-img>
                            </v-card>
                        </template>
                    </v-radio>
                </v-radio-group>
                 <span v-else>

                </span>
            </v-card-text>
        </v-card>


        <!--                            <v-col cols="12" md="5" lg="5" sm="12">-->
        <!--                                <v-card>-->
        <!--                                    <v-card-title>Section Preview</v-card-title>-->
        <!--                                    <v-img-->
        <!--                                        v-bind:src="selectedLayout.thumbnail_image ? selectedLayout.thumbnail_image : '/assets/media/logos/icon-no-image.svg'"-->
        <!--                                    ></v-img>-->
        <!--                                </v-card>-->
        <!--                            </v-col>-->
        <!--                            <v-col class="text-right">-->
        <!--                                <v-btn-->
        <!--                                    @click="closeDialog"-->
        <!--                                >Cancel-->
        <!--                                </v-btn>-->
        <!--                                <v-btn-->
        <!--                                    class="btn btn-primary"-->
        <!--                                    :disabled="selectedLayout.id ? false : true"-->
        <!--                                    @click="update"-->
        <!--                                >save-->
        <!--                                </v-btn>-->
        <!--                            </v-col>-->
        <v-dialog width="900" v-model="previewDialog">
            <v-card>
                <v-card-text>
                    <v-card>
                        <v-card-title>Section Preview</v-card-title>
                        <v-img
                                v-bind:src="selectedLayout.thumbnail_image ? selectedLayout.thumbnail_image : '/assets/media/logos/icon-no-image.svg'"
                        ></v-img>
                    </v-card>
                </v-card-text>
                <v-card-action>
                    <v-row class="">
                        <v-col class="pull-right">
                            <v-btn class="pull-right btn btn-standard" @click="previewDialog=false">Close</v-btn>
                        </v-col>
                    </v-row>

                </v-card-action>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import Websitelayout from "@/services/Websites/WebsiteLayoutService";
    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
    import Website from "@/services/Websites/WebsiteService";

    const WebsiteLayoutService = new Websitelayout();

    const WebsiteSettingService = new WebsiteSetting();

    const WebsiteService = new Website();
    export default {
        name: "websitelayout",
        props: ['type'],
        data() {
            return {
                pageSetting: {
                    id: null,
                    site_logo: null,
                    site_favicon: null,
                    site_title: null,
                    site_tagline: null,
                    site_phone: null,
                    site_email: null,
                    site_fax: null,
                    business_address: null,
                    seo_title: null,
                    seo_description: null,
                    social_share_image: null,
                    social_share_description: null,
                    enable_powered_by: 1,
                    mailchimp_api_key: null,
                    google_map_api_key: null,
                    google_analytics_api_key: null,
                    google_map_embed_url: null,
                    header_design_id: null,
                    footer_design_id: null,
                    cart_page_design_id: null,
                    cart_page_title: null,
                    cart_page_header: null,
                    cart_page_subheader: null,
                    cart_page_banner: null,
                    shop_main_design_id: null,
                    shop_page_title: null,
                    shop_page_header: null,
                    shop_page_subheader: null,
                    shop_page_banner: null,
                    product_per_page: null,
                    thank_you_page_design_id: null,
                    thank_you_page_title: null,
                    thank_you_page_text: null,
                    thank_you_page_header: null,
                    thank_you_page_subheader: null,
                    thank_you_page_banner: null,
                    checkout_page_design_id: null,
                    blog_main_design_id: null,
                    blog_single_design_id: null,
                    blog_page_title: null,
                    blog_page_header: null,
                    blog_page_subheader: null,
                    blog_page_banner: null,
                    blog_per_page: null,
                    login_design_id: null,
                    registration_design_id: null,
                    product_single_design_id: null,
                    product_layout_design_id: null,
                    setup_steps: null,
                    is_active: 0,
                    deleted_at: null,
                    updated_at: null
                },
                selectedLayout: {
                    id: null,
                    title: "",
                    path: "",
                    thumbnail_thumb_image: "",
                    thumbnail_image: "",
                    type: "",
                    is_active: 1
                },
                layouts: [],
                website: '',
                previewDialog: false,
            };
        },
        components: {
            KTPortlet
        },
        methods: {
            getLayouts() {
                WebsiteLayoutService.filter(this.type).then(res => {
                    if (res.data.data.length > 0) {
                        this.layouts = res.data.data;
                    } else {
                        this.reset();
                    }
                });
            },
            reset() {
                this.layouts = [];
                this.selectedLayout = {
                    id: null,
                    title: "",
                    path: "",
                    thumbnail_thumb_image: "",
                    thumbnail_image: "",
                    type: "",
                    is_active: 1
                };
            },
            changeType(type) {

            },
            previewImage(layout){
                this.previewDialog = true;
                this.selectedLayout = layout;
            },
            getSettings() {
                WebsiteSettingService.paginate(this.siteUrl).then(res => {
                    this.pageSetting = res.data;
                });
            },
            update() {
                switch (this.type) {
                    case "header":
                        this.pageSetting.header_design_id = this.selectedLayout.id;
                        break;
                    case "footer":
                        this.pageSetting.footer_design_id = this.selectedLayout.id;
                        break;
                    case "blog_category":
                        this.pageSetting.blog_main_design_id = this.selectedLayout.id;
                        break;
                    case "blog_single":
                        this.pageSetting.blog_single_design_id = this.selectedLayout.id;
                        break;
                    case "catalog":
                        this.pageSetting.shop_main_design_id = this.selectedLayout.id;
                        break;
                    case "checkout":
                        this.pageSetting.checkout_page_design_id = this.selectedLayout.id;
                        break;
                    case "default_template":
                        this.pageSetting.template = this.selectedLayout.id;
                        break;
                    case "login":
                        this.pageSetting.login_design_id = this.selectedLayout.id;
                        break;
                    case "product_layout":
                        this.pageSetting.product_layout_design_id = this.selectedLayout.id;
                        break;
                    case "product_single":
                        this.pageSetting.product_single_design_id = this.selectedLayout.id;
                        break;
                    case "registration":
                        this.pageSetting.registration_design_id = this.selectedLayout.id;
                        break;
                    case "thank_you":
                        this.pageSetting.thank_you_page_design_id = this.selectedLayout.id;
                        break;
                }

                this.updateSetting();
            },
            updateSetting() {
                WebsiteSettingService.update(
                    this.siteUrl,
                    this.pageSetting.id,
                    this.pageSetting
                ).then(res => {
                    this.$snotify.success("Settings  Updated");
                    this.initSettings();
                    this.$emit("closeDialog");
                });
            },
            closeDialog() {
                this.$emit("closeDialog");
            },
            initSettings() {
                // this.getLayouts();
                this.getSettings();
                this.getLayouts();
            },

            getWebsite() {

                WebsiteService.get(
                    this.siteUrl
                ).then(res => {
                    this.website = res.data.website;

                });
            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            }
        },
        mounted() {
            this.initSettings();
            this.getWebsite();
        }
    };
</script>
