import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteFrontStoreContent {

    paginate(slug, filter, index = null) {
        let url = API_URL + 'user/website/' + slug + '/front-store-content';
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/front-store-content'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/front-store-content/update/' + id
        return apiService.post(url, data);
    }

    sort(domain_name, data) {
        let url = API_URL + 'user/website/' + domain_name + '/front-store-content/item/sortable';
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/front-store-content/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/front-store-content/' + id
        return apiService.get(url);
    }


}
