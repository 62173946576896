import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteHeaderService {


    paginate(slug, index = null) {
        let url = API_URL + 'user/website/' + slug + '/header';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/header'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/header/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/header/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/header/' + id
        return apiService.get(url);
    }

    getGoogleFonts() {
        let url = API_URL + 'user/website/fonts/get-google-fonts'
        return apiService.get(url);
    }

    removeImage(websiteId, image) {
        let url = API_URL + `user/website/${websiteId}/remove-header-image/${image}`;
        return apiService.get(url);
    }


}
