import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StateService {


      #api;
      constructor(){
            this.#api = "user/country";
      }
      all(country){
            let url  = `${this.#api}/${country}/state`;
            return apiService.get(url);
      }
}
